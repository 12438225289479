module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Прораб","short_name":"Прораб","description":"Medcheck - с улыбкой о здоровье","lang":"ru","start_url":"/","background_color":"#545ee0","theme_color":"#545ee0","display":"fullscreen","icon":"src/assets/images/favicon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc09435fc9e6f9465b503093ad621eed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"_","debug":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-H5L9Y4VS7J"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ky","ru","uz"],"siteUrl":"http://www.www.medcheck.kg","redirect":false,"defaultLanguage":"ru","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"detection":{"order":["navigator"],"caches":["cookie"]}},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"blue","parent":"body","template":"\n        <div class=\"bar\" role=\"progressbar\" aria-valuemin=\"10\" aria-valuemax=\"15\">\n          <div class=\"peg\"></div>\n        </div>\n        <div class=\"custom-spinner\" role=\"progressbar\" aria-valuemin=\"10\" aria-valuemax=\"15\">\n          <div class=\"custom-spinner-icon nprogress-icon\"></div>\n        </div>\n      ","minimum":0.4},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
